
import { defineComponent, ref } from 'vue';
import buildContentModel from '@/components/view/installer/community/build-content-model';
import buildTree from '@/components/view/installer/community/build-tree';
import changeTextByScene from '@/methods/setText';

export default defineComponent({
    components: {
        buildContentModel,
        buildTree
    },
    props: {
        deviceType: {
            type: String,
            default: 'camera'
        }
    },
    setup() {
        changeTextByScene();
        const build = ref('');
        const changeBuild = (id: string) => {
            build.value = id;
        };
        return {
            changeBuild,
            build
        };
    }
});
